<template>
  <div class="content">
    <heads></heads>
    <div style="height:112px"></div>
    <div class="main">
      <div class="main-box">
        <div class="title">{{ $t('login.cj') }}</div>
        <div class="user">{{ $t('login.yxdz') }}</div>
        <div class="ipt-kuang" style="margin-bottom: 15px">
          <img class="user-icon" src="../../assets/yx.png" alt="" />
          <input type="text" v-model="mailbox" class="username" :placeholder="$t('login.srdz')" />
        </div>
        <div class="user">{{ $t('login.sz') }}</div>
        <div class="ipt-kuang">
          <img class="user-icon" src="../../assets/pwd.png" alt="" />
          <input type="password" v-model="password" class="username" :placeholder="$t('login.pwd')" />
        </div>
        <div class="user">{{ $t('login.yxyz') }}</div>
        <div class="ipt-kuang">
          <img class="user-icon" src="../../assets/yz.png" alt="" />
          <input type="text" v-model="code" class="username" :placeholder="$t('login.yzm')" />
          <el-button :disabled="disabled" @click="getCode">{{!codeTime ? $t('login.send') : codeTime + 's'}}</el-button>
        </div>
        <div class="reg-flex">
          <div></div>
          <div @click="jumpLogin" style="font-size:12px;">{{ $t('login.yyzh') }}？<span style="color:#FFC300">{{
            $t('login.qdl')
          }} </span></div>
        </div>
        <div class="btn" @click="reg">{{ $t('login.zc') }}</div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
import heads from '../../components/header.vue'
import footerb from '../../components/footer.vue'

import {sendEmail} from '@/api/conmmon.js';
import {register} from '@/api/member.js';

export default {
  data() {
    return {
      isLogin: false,//登录
      isReg: true,//注册页
      isForget: false,//忘记密码页
      isNext: false,
      disabled: false,
      activeIndex: 0,
      password: "",
      mailbox: '',//邮箱
      code: '',//验证码
      codeTime: 0
    }
  },
  components: {
    footerb,
    heads
  },
  methods: {
    btn(id) {
      this.activeIndex = id
    },
    jumpLogin() {
      this.$router.push('./login')
    },
    getCode() {
      if (this.codeTime > 0) {
        return;
      }
      if (!this.mailbox) {
        return this.$message.error(this.$t('login.srdz'))
      }
      this.disabled = true;
      sendEmail({email: this.mailbox,type: 'register'}).then(res => {
        if (res.code == 1) {
          this.codeTime = 59;
          let timer = setInterval(() => {
            this.codeTime--;
            if (this.codeTime < 1) {
              clearInterval(timer);
              this.codeTime = 0
              this.disabled = false;
            }
          }, 1000)
        } else {
          this.disabled = false;
        }
      })

    },
    reg() { //注册
      if (!this.mailbox) {
        return this.$message.error(this.$t('login.srdz'))
      }
      if (!this.password) {
        return this.$message.error(this.$t('login.pwd'))
      }
      if (!this.code) {
        return this.$message.error(this.$t('login.yzm'))
      }
      register({
          email: this.mailbox,
          pass: this.password,
          code: this.code
        }).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.$router.push('/login')
          }, 500)
        }
      })
    }
  }
};
</script>
<style scoped>
html {
  background: #171e28;
}


/* 主体部分 */
.main {
  width: 100%;
  height: 100%;
  margin-bottom: 112px;
}

.main-box {
  width: 534px;
  height: auto;
  border-radius: 30px;
  background: #1f282f;
  /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute; */
  padding: 50px 90px;
  box-sizing: border-box;
  margin: 0 auto;
}

.title {
  text-align: center;
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 55px;
}

.el-button {
  background: none !important;
  padding: 0 !important;
  border: none !important;
  color: #FFC300;
  font-size: 14px !important;
}

.ipt-kuang {
  width: 100%;
  height: 55px;
  border-radius: 4px;
  background: #171e28;
  border: 1px solid #ffffff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.user {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 10px;
}

.username {
  font-size: 14px;
  color: #ffffff;
  width: 100%;
}

.user-icon {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}

.reg-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 58px;
  cursor: pointer;
}

.btn {
  width: 100%;
  height: 55px;
  border-radius: 4px;
  background: rgba(255, 195, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
}

.hint {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  margin-bottom: 52px;
}

.hint-reset {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hint img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
</style>
